export const inviteLinkFormat = (ownerName: string, inviteLinkUrl: string) => {
  return `${ownerName} 様\n下記の招待リンクからEQUTUMへご参加ください。\n愛馬のレポートなどを配信いたします。\n${inviteLinkUrl}`;
};

export const normalizeString = (str: string) => {
  return str
    .normalize('NFKC')
    .toLowerCase()
    .replace(/[\u3041-\u3096]/g, (match) => String.fromCharCode(match.charCodeAt(0) + 0x60));
};

export const getGenderString = (gender: string) => {
  return gender === 'male' ? '牡' : gender === 'female' ? '牝' : gender === 'gelding' ? 'セン' : '';
};

export const getGenderFullString = (gender: string) => {
  return gender === 'male'
    ? '牡馬'
    : gender === 'female'
      ? '牝馬'
      : gender === 'gelding'
        ? 'セン馬'
        : '';
};

export const convertHorseShoeingTreatmentTypeToJapanese = (horseShoeingTreatmentType: string) => {
  switch (horseShoeingTreatmentType) {
    case 'REPLACEMENT':
      return '打ち替え';
    case 'DROP':
      return '落鉄';
    case 'REPAIR':
      return '修正';
    default:
      return '未選択';
  }
};

export const convertTrainingTypeToJapanese = (trainingType: string) => {
  switch (trainingType) {
    case 'EXERCISE':
      return '運動';
    case 'WORKOUT':
      return '追切';
    case 'POOL':
      return 'プール';
    case 'GATE':
      return 'ゲート';
    default:
      return '';
  }
};

export const convertGateTrainingTypeToJapanese = (gateTrainingType: string) => {
  switch (gateTrainingType) {
    case 'BEFORE':
      return '前出し';
    case 'AFTER':
      return '後出し';
    case 'TEST':
      return '試験';
    default:
      return '';
  }
};

export const convertGoingToJapanese = (going: string | undefined) => {
  if (!going) {
    return '';
  }
  switch (going) {
    case 'good_to_firm':
      return '良';
    case 'good':
      return '稍重';
    case 'yielding':
      return '重';
    case 'soft':
      return '不良';
    default:
      return '';
  }
};

export const convertMarginToJapanese = (margin: string) => {
  if (!margin) return '';
  switch (margin) {
    case 'LARGE':
      return '大';
    case 'SMALL':
      return '小';
    case 'SAME':
      return '併入';
    default:
      return '';
  }
};

export const convertStartingOrderToJapanese = (order: number) => {
  if (!order) return '';
  switch (order) {
    case 1:
      return '1';
    case 2:
      return '2';
    case 3:
      return '3';
    default:
      return '';
  }
};

export const convertIntensityToJapanese = (intensity: string) => {
  if (!intensity) return '';
  switch (intensity) {
    case 'LOOSE':
      return '馬なり';
    case 'INTENSE':
      return '強め';
    case 'FULL':
      return '一杯';
    case 'END_FULL':
      return '終い一杯';
    default:
      return '';
  }
};

export const convertTrackPositionToJapanese = (position: string) => {
  if (!position) return '';
  switch (position) {
    case 'INNER':
      return '内';
    case 'MIDDLE':
      return '中';
    case 'OUTER':
      return '外';
    default:
      return '';
  }
};

export const convertDetailToJapanese = (detail: string) => {
  if (!detail) return '';
  switch (detail) {
    case 'LEADING':
      return '先行';
    case 'PAIRED':
      return 'テン併せ';
    case 'CHASE':
      return '追走';
    default:
      return '';
  }
};
